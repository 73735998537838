import React from 'react';

export const NoConnection = () => {
  return (
    <div className="submission">
      <div className="submission-top">
        <div className="title">Нет связи с сервером</div>
      </div>
    </div>
  );
};
