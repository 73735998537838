import React from 'react';

export const NoData = () => {
  return (
    <div className="submission">
      <div className="submission-top">
        <div className="title">Нет данных</div>
      </div>
    </div>
  );
};
